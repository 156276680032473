<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="primary"
    flat
  >
    <v-btn 
      class="hidden-md-and-up"
      text
      color="white"
      @click="$router.replace('/')"
      >
        <v-icon
        >
          mdi-arrow-left-thick 
        </v-icon>
    </v-btn>
    <v-btn 
      class="mr-5 hidden-sm-and-down"
      text
      @click="$router.replace('/')"
      >
        <v-img 
          width="150"
          eager 
          :src="logoHorizontalBranca"/>
    </v-btn>
    <v-divider 
      light
      vertical
      color="white"
      class="mr-5"
    />
    <v-toolbar-title
      class="font-weight-light white--text"
      v-text="courseTitle"
    />
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib"

// Utilities
import { mapState, mapMutations } from "vuex"

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              )
            },
          },
        })
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one",
    ],
  }),

  computed: {
    ...mapState(["drawer", "courseTitle",'logoHorizontal','logoHorizontalBranca']),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
      setCourseTitle: "SET_COURSE_TITLE",
    }),
  },
};
</script>
<style scoped>
  #app-bar ::v-deep div.v-toolbar__content{
    padding-left: 0;
  }
</style>
